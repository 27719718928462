<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-select placeholder="状态" v-model="query.status" clearable>
          <i-option value="-1">已废弃</i-option>
          <i-option value="4">待审批</i-option>
          <i-option value="5">已通过</i-option>
          <i-option value="6">已拒绝</i-option>
          <!-- <i-option value="9">已归档</i-option> -->
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-input  placeholder="关键字：编码、品牌等" v-model="query.keyword">
        </i-input>
      </i-col>
       <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-button class="m-r-10" type="primary" icon="ios-search" long @click="handleSearch">搜索</i-button>
        <!-- <i-button  icon="md-cloud-download" type="info" >导出Excel</i-button> -->
      </i-col>
    </Row>

    <!-- 变更列表 -->
  <Table
      stripe
      :data="list"
      :columns="titles"
    ></Table>
    <!-- <i-table stripe :data="list" :columns="titles"></i-table> -->
    <div class="paging_style">
      <Page size="small" :page-size="query.pageSize" :total="total" @on-change="handlePageChanged" show-total show-elevator></Page>
    </div>

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
import { formatStatementStatus } from '@/utils/tagStatus'

import { getStatementPage } from '@/api/scp/statement'

export default {
  data () {
    return {
      total: 0,
      list: [],
      query: { pageNumber: 1, pageSize: 15, status: null, keyword: '' },
      titles: [
        { title: '编码', key: 'code' },
        { title: '广告主', key: 'advertiserName' },
        { title: '品牌', key: 'name' },
        {
          title: '所属代理商/销售',
          render: (h, data) => {
            return h('span', data.row.companyName + ' / ' + data.row.userName)
          }
        },
        {
          title: '起止日期',
          render: (h, data) => {
            return h('div', [
              h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate)),
              h('span', { style: { color: '#ed4014' } }, '(' + GetPublishDay(data.row.startDate, data.row.endDate) + ')')
            ])
          }
        },
        {
          title: '审批通过时间(最近/首次)',
          width: '160',
          align: 'center',
          render: (h, data) => {
            const tmp = []
            tmp.push(h('p', data.row.approvedTime))
            if (data.row.approvedTimeList.length > 1) {
              tmp.push(h('p', `(${data.row.approvedTimeList[0]})`))
            }
            return h('div', tmp)
          }
        },
        {
          title: '金额',
          align: 'right',
          render: (h, data) => {
            return h('span', toMoney(data.row.amount || 0))
          }
        },
        {
          title: '已回款金额',
          align: 'right',
          render: (h, data) => {
            return h('span', toMoney(data.row.receivedAmount || 0))
          }
        },
        {
          title: '状态',
          render: (h, data) => {
            return formatStatementStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            return h(
              'a',
              {
                style: {
                  'margin-left': '5px'
                },
                on: {
                  click: () => {
                    this.handleDetail(data.row.id)
                  }
                }
              },
              '详情'
            )
          }
        }
      ]
    }
  },
  created () {
    this.initPage()
  },
  methods: {
    initPage () {
      this.getStatementList()
    },
    getStatementList () {
      if (!this.query.status) {
        this.query.statusList = JSON.stringify([-1, 4, 5, 6, 9])
      } else {
        this.query.statusList = JSON.stringify([this.query.status])
      }
      getStatementPage(this.query).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleDetail (statementId) {
      this.$emit('on-showDetail', statementId)
    },

    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getStatementList()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getStatementList()
    }
  }
}
</script>
